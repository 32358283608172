/***
 *
 * App provider for system
 *
 */
import { LogoutOptions, RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import React, { createContext, useCallback, useContext, useEffect } from 'react';

import { ReactChildrenProps } from '../../types/react.types';

import { gql } from '@urql/core';

import { useImmer } from 'use-immer';
import { PropType } from '../../shared/shared.types';
import { useClient } from 'urql';
import { getClientEnv } from '../clientEnv';
import { SpinNotificationProvider } from './SpinNotificationProvider';
import {
    LoginToSpinMutation,
    LoginToSpinMutationVariables,
    LoginToSpinDocument,
    Espin_Role_Enum,
    Espin_Subscription_Status_Enum,
} from '../../generated/spin-graphql';

const stub = (): never => {
    throw new Error('You forgot to wrap your component in <AppStateProvider>.');
};

export type AppStateUser = PropType<LoginToSpinMutation, 'insert_espin_user_one'>;
type ControlledAppState = {
    user: AppStateUser | undefined;
    isLoggingIn: boolean;
    subscribed: boolean;
    subtier: number;
    selectedChild: number;
};

type AppState = ControlledAppState & {
    isAuthenticated: boolean;
    isLoading: boolean;
    a0user: any;
    isLoggedIn: boolean;
    getAccessTokenSilently: () => Promise<string>;
    logout: (options?: LogoutOptions) => void;
    loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>;
    reloadUser: () => Promise<void>;
    selectChild: (value: number) => void;
};

const AppStateContext = createContext<AppState>({
    isAuthenticated: false,
    isLoading: true,
    loginWithRedirect: stub,
    a0user: undefined,
    isLoggedIn: false,
    logout: stub,
    getAccessTokenSilently: stub,
    reloadUser: stub,
    selectChild: stub,
    user: undefined,
    isLoggingIn: true,
    subscribed: false,
    subtier: 0,
    selectedChild: 0,
});

// this will be an upsert mutation... a whaaa? yes. you heard right sir.

gql`
    fragment LoginData on espin_user {
        id
        role
        email
        last_login
        subscription_status {
            is_subscribed
            tierlookup
        }
        college_profile {
            id
            first_name
            last_name
            last_group_message
            college {
                id
                logo {
                    ...FormFile
                }
                subscribed
                manual_subtier
                program_name
                address {
                    location
                    state
                }
                verified
                owner_profile_id
                subscription {
                    referral_code_redemption_id
                }
            }
        }
        high_school_profile {
            id
            first_name
            last_name
            high_school {
                id
                name
                verified
                owner_profile_id
                profile_pic_file {
                    ...FormFile
                }
            }
        }
        parent_profile {
            first_name
            last_name
            id
            child_profiles(where: { user: { deleted_at: { _is_null: true } } }) {
                id
                slug
                user_id
                user {
                    subscription_status {
                        is_subscribed
                        tierlookup
                    }
                }
                trial_end_date
                referral_option_id
                spin_username
                first_name
                last_name
                profile_image {
                    ...FormFile
                }
                subscription {
                    id
                    expires
                    referral_code_redemption_id
                }
                player_games {
                    primary
                    game {
                        game_definition
                    }
                }
            }
            address {
                location
                state
            }
        }
        player_profile {
            id
            spin_username
            ack_terms
            ack_waiver
            ack_displayed
            trial_end_date
            referral_option_id
            profile_image {
                ...FormFile
            }
            player_games {
                primary
                game {
                    game_definition
                }
            }
            address {
                location
                state
            }
            subscription {
                referral_code_redemption_id
            }
        }
    }

    mutation LoginToSpin {
        insert_espin_user_one(
            object: { client_login: "now()" }
            on_conflict: {
                constraint: user_pkey
                update_columns: [updated_at, client_login]
                where: { deleted_at: { _is_null: true } }
            }
        ) {
            ...LoginData
        }
    }
`;

export function AppStateProvider({ children }: ReactChildrenProps): JSX.Element {
    const { isAuthenticated, isLoading, loginWithRedirect, logout, user: a0user, getAccessTokenSilently } = useAuth0();

    const gqlClient = useClient();

    const _loginToSpin = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();
        // console.log({ accessToken });
        return gqlClient
            .mutation<LoginToSpinMutation, LoginToSpinMutationVariables>(
                LoginToSpinDocument,
                {},
                {
                    url: getClientEnv().NEXT_PUBLIC_GQL_URL,
                    fetchOptions: {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    },
                    additionalTypenames: [
                        'espin_user',
                        'espin_player_profile',
                        'espin_parent_profile',
                        'espin_college_profile',
                        'espin_college',
                        'espin_address',
                    ],
                },
            )
            .toPromise();
    }, [getAccessTokenSilently, gqlClient]);
    const [internalState, setInternalAppState] = useImmer<ControlledAppState>({
        user: undefined,
        isLoggingIn: true,
        subscribed: false,
        subtier: 0,
        selectedChild: 0,
    });

    const selectChild = useCallback(
        (value: number) => {
            setInternalAppState((draft) => {
                draft.selectedChild = value;
            });
        },
        [setInternalAppState],
    );

    const reloadUser = useCallback(async () => {
        const loginReply = await _loginToSpin();
        if (loginReply.error) {
            console.error(loginReply.error);
            return;
        }
        if (loginReply.data) {
            setInternalAppState((draft) => {
                draft.user = loginReply.data.insert_espin_user_one;
                draft.isLoggingIn = false;
            });
        }
    }, [_loginToSpin, setInternalAppState]);

    // subscription effect that changes with user
    useEffect(() => {
        // CEH-600 - player sub hot fix to revert
        if (!isLoading && isAuthenticated && !!internalState.user) {
            if (
                internalState.user.role === Espin_Role_Enum.EspinPlayerUser ||
                internalState.user.role === Espin_Role_Enum.EspinCollegeUser
            ) {
                setInternalAppState((draft) => {
                    // CEH-600 - player sub hot fix to revert
                    draft.subscribed = internalState.user.subscription_status.is_subscribed;
                    draft.subtier = internalState.user.subscription_status.tierlookup;
                });
            } else if (internalState.user.role === Espin_Role_Enum.EspinParentUser) {
                setInternalAppState((draft) => {
                    // we're assuming only one child for now
                    // TODO: update this when we find a better solution for multiple children
                    // CEH-600 - player sub hot fix to revert
                    //draft.subscribed = internalState.user.parent_profile.child_profiles[internalState.selectedChild].user.subscription_status.is_subscribed;
                    draft.subscribed =
                        internalState.user.parent_profile?.child_profiles[0]?.user.subscription_status.is_subscribed ??
                        false;
                    draft.subtier =
                        internalState.user.parent_profile?.child_profiles[0]?.user.subscription_status.tierlookup ?? 0;
                });
            } else if (internalState.user.role === Espin_Role_Enum.EspinHighschoolUser) {
                // THIS SHOULD BE UPDATED IN CEH-900
                setInternalAppState((draft) => {
                    draft.subscribed = true;
                });
            }
        }
    }, [isLoading, isAuthenticated, internalState.user, setInternalAppState, internalState.selectedChild]);

    // ok - let's rethink this.
    // i need to call login ONCE i have auth0 ready + then that's it.
    useEffect(() => {
        if (!isLoading && isAuthenticated && !!!internalState.user) {
            // call and set our data here.
            (async () => {
                const loginReply = await _loginToSpin();
                if (loginReply.error) {
                    console.error(loginReply.error);
                    setInternalAppState((draft) => {
                        draft.isLoggingIn = false;
                    });
                    return;
                }
                // deleted_at is not NULL, meaning user is deleted -> should catch this
                else if (!!!loginReply.data.insert_espin_user_one) {
                    setInternalAppState((draft) => {
                        draft.isLoggingIn = false;
                    });
                    return;
                }
                if (loginReply.data) {
                    setInternalAppState((draft) => {
                        draft.user = loginReply.data.insert_espin_user_one;
                        draft.isLoggingIn = false;
                    });
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, isLoading, _loginToSpin]);

    // if i'm not logged into A0 at all
    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            setInternalAppState((draft) => {
                draft.user = undefined;
                draft.isLoggingIn = false;
            });
        }
    }, [isLoading, isAuthenticated, setInternalAppState]);

    return (
        <AppStateContext.Provider
            value={{
                // auth0 specific
                isAuthenticated,
                isLoading,
                loginWithRedirect,
                logout,
                reloadUser,
                a0user,
                getAccessTokenSilently,
                // app state
                selectChild,
                selectedChild: internalState.selectedChild,
                user: internalState.user,
                isLoggingIn: internalState.isLoggingIn,
                // a combo of the both
                isLoggedIn: !internalState.isLoggingIn && !!internalState.user,
                subscribed: internalState.subscribed,
                subtier: internalState.subtier,
            }}
        >
            <SpinNotificationProvider>{children}</SpinNotificationProvider>
        </AppStateContext.Provider>
    );
}

export const useAppState = (): AppState => useContext(AppStateContext) as AppState;
